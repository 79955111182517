import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import FinancialAid from '../components/financialAid'


const FinancialAidPage = () => (
  <Layout>
    <SEO title="Financial Aid" />
    <br/>
    <FinancialAid />
    {/* <NetPriceCalculator /> commenting out based on client request */}
    <br /><br /><br /><br />
  </Layout>
)

export default FinancialAidPage