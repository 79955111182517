import React, { useState } from "react"
import { Container } from 'reactstrap';

import FinancialAidInfo from '../assets/Student-Financial-Aid-Information.pdf';
import DisclosureModal from "./disclosureModal";

import StudentLoanCodeOfConduct from '../assets/Student-Loan-Code-of-Conduct.pdf'


export default class FinancialAssistance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalContent: ""
        };
    }

    toggle = content => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalContent: content
        }));
    }

    closeModal = () => {
        this.setState({
            modal: false
        })
    }

    render() {
        return (
            <section>
                <Container>
                    <h2 style={{textAlign: "center"}}>Financial Aid</h2>
                    <hr />
    
                    <p>This page contains disclosure information required for compliance of Title IV regulations</p>
                    <p dangerouslySetInnerHTML={{__html: 'FREE application for Federal Student Aid can be found at <a href="http://www.fafsa.ed.gov" target="_blank">www.fafsa.ed.gov</a>'}}></p>
                    <p>KCK Beauty & Barber Academy School code is <strong>042768</strong></p>
                    <div>
                        <p>Students seeking Financial Aid, Please reference the following resources:</p>
                        <p><strong>Financial Aid is available for those who qualify for all of our programs 600 hours or more.</strong></p>
                        <ul>
                        <li dangerouslySetInnerHTML={{__html: `<a href="${FinancialAidInfo}" target="_blank"><u>Financial Aid Information</u></a>`}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="https://nces.ed.gov/collegenavigator/" target="_blank"><u>College Navigator</u></a>'}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html" target="_blank"><u>FERPA(Family Educational Rights &amp; Privacy Act)</u></a>'}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="http://web.archive.org/web/20190114175756/https://nslds.ed.gov/nslds/nslds_SA/" target="_blank"><u>NSLDS-My Financial Aid History</u></a>'}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="https://studentloans.gov/myDirectLoan/index.action" target="_blank"><u>Federal Direct Loans</u></a>'}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="https://studentaid.ed.gov/sa/fafsa/next-steps/entrance-counseling" target="_blank"><u>Entrance Counseling &amp; Sign Master Promissory Note (MPN)</u></a>'}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="https://studentaid.ed.gov/sa/" target="_blank"><u>Parent Plus Loan</u></a>'}}></li>
                            <li dangerouslySetInnerHTML={{__html: '<a href="https://studentloans.gov/myDirectLoan/counselingInstructions.action?counselingType=exit" target="_blank"><u>Exit Counseling</u></a>'}}></li>
                            <li><a style={{textDecoration: 'underline'}} href="#" onClick={e => this.toggle(`<p>KCK Beauty &amp; Barber Academy participates in the William D. Ford Federal Direct Loan Program which includes the Direct Subsidized, Direct Unsubsidized and the Direct Parent PLUS Loans. To comply with the Higher Education Opportunity Act of 2008, the institution follows legislated requirements which prohibit a conflict of interest with the responsibilities of an officer, employee, or agent of the school regarding Title IV loans.</p><p>Code of Conduct, <a href="${StudentLoanCodeOfConduct}" target="_blank">click here</a></p>`)}>Education Loan Code of Conduct</a></li>
                            <li><a style={{textDecoration: 'underline'}} href="#" onClick={e => this.toggle('<p>Our school catalog provides official information regarding the schools cancellation & refund policy.</p>')}>Cancellation and Refund Policy</a></li>
                            <li><a style={{textDecoration: 'underline'}} href="#" onClick={e => this.toggle('<p>Our school catalog provides official information regarding requirements for Withdrawal.</p>')}>Withdrawal Policy</a></li>
                            <li><a style={{textDecoration: 'underline'}} href="#" onClick={e => this.toggle('<p>Our school catalog provides official information regarding Return of Title IV Financial Aid.</p>')}>Return to Title IV Funds</a></li>
                        </ul>
                    </div>
    
                    <DisclosureModal modalOpen={this.state.modal} modalClose={this.closeModal} toggleState={this.toggle} content={this.state.modalContent}/>
                </Container>
            </section>
        )
    }
}

// export default FinancialAssistance